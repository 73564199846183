<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0944 10.6165L10.88 10.8674L12.3474 13.3188C12.4495 13.4887 12.3802 13.7 12.2036 13.797L11.2248 14.3203C11.0424 14.418 10.8177 14.3555 10.7162 14.191L9.32197 11.8633L7.71082 13.7616C7.49611 14.0145 7.08482 13.8849 7.05023 13.589L6.09608 5.42644C6.05967 5.11489 6.45776 4.9159 6.69956 5.11061L13.2862 9.99533C13.5505 10.1806 13.4181 10.5798 13.0944 10.6165Z"
      fill="currentColor"
    />
    <path
      d="M5.80998 2.74093L4.7175 0.921473L3.60898 1.53686L4.70146 3.35631L5.80998 2.74093Z"
      fill="currentColor"
    />
    <path
      d="M3.77536 4.99671L1.6649 4.45297L1.33398 5.64215L3.44412 6.18536L3.77536 4.99671Z"
      fill="currentColor"
    />
    <path
      d="M8.98668 3.10844L9.55218 1.07916L8.31598 0.760658L7.7508 2.79047L8.98668 3.10844Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcCursor',
  props: {
    size: {
      type: [String, Number],
      default: 18,
    },
  },
}
</script>

<style scoped lang="scss"></style>
