<template>
  <div>
    <div
      v-if="isPageLoading"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>

    <monitoring-form
      :campaign-options="campaignOptions"
      :top-keywords="topKeywords"
      :top-geo-locations="geoTopGeoLocations"
      :is-fetching-data="isFetchingData"
      @select-campaign="selectCampaign"
      @select-geo-location="selectGeoLocation"
      @start-monitoring="startMonitoring"
    />
  </div>
</template>

<script>
import MonitoringForm from '@/views/site/search/competitor_monitoring/cms_onboarding/MonitoringForm.vue'
import { mapActions, mapState } from 'vuex'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'

export default {
  name: 'CmsOnboarding',
  components: { BrightbidLoader, MonitoringForm },
  data() {
    return {
      topKeywords: [],
      geoTopGeoLocations: [],
      selectedLocations: [],
      isFetchingData: false,
      isPageLoading: false,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('search', { campaigns: 'campaignsAll' }),
    campaignOptions() {
      if (!this.campaigns) {
        return []
      }
      return this.campaigns.map(campaign => ({ value: campaign.campaign_id, label: campaign.name }))
    },
  },
  async mounted() {
    if (!this.campaigns || this.campaigns.length === 0) {
      await this.fetchSiteCampaignsAll()
    }
  },
  methods: {
    ...mapActions('competitorMonitoring', ['loadMonitoringJob']),
    ...mapActions({ loadSearchCampaignsAll: 'search/loadSearchCampaignsAll' }),
    ...mapActions('toast', ['loadToast']),
    /*
     * Fetch all campaigns
     */
    async fetchSiteCampaignsAll() {
      try {
        const { data: campaigns } = await this.$http.get(`/search/site/${this.selectedSite.value}/campaigns`, {
          params: {
            managed: false,
          },
        })
        await this.loadSearchCampaignsAll(campaigns)
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to load campaigns all. Please try again later.',
          type: 'error',
        })
      }
    },
    /*
     * Get top geo-locations
     */
    async getTopGeoLocations(campaignId) {
      try {
        let { data: topGeoLocations } = await this.$http.get(
          `/search/site/${this.selectedSite.value}/campaign/${campaignId}/top-n-geo-locations/5`,
        )

        topGeoLocations = topGeoLocations.filter(location => {
          return !Object.prototype.hasOwnProperty.call(location, 'country_code') || !!location.country_code
        })

        return topGeoLocations
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get top geo locations. Please try again later.',
          type: 'error',
        })
      }
    },
    selectGeoLocation(geoLocation) {
      this.selectedLocations = geoLocation
    },

    /*
     * Get top N keywords
     */
    async getTopNKeywordsClicks(nKeywords = 5) {
      try {
        const { data: topKeywords } = await this.$http.get(
          `/search/site/${this.selectedSite.value}/campaign/${this.selectedCampaign.value}/fetch-top-n-keywords-clicks/${nKeywords}`,
        )
        return topKeywords
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get top keywords. Please try again later.',
          type: 'error',
        })
      }
    },

    async selectCampaign(campaign) {
      this.isPageLoading = true
      this.isFetchingData = true

      this.selectedCampaign = campaign

      const [topGeoLocations, topKeywords] = await Promise.all([
        this.getTopGeoLocations(this.selectedCampaign.value),
        this.getTopNKeywordsClicks(5),
      ])
      this.topKeywords = topKeywords || []
      this.geoTopGeoLocations = topGeoLocations || []

      this.isFetchingData = false
      this.isPageLoading = false
    },
    async startMonitoring(keywords, subscriptionType, jobFrequency) {
      this.isPageLoading = true

      const monitoringJob = await this.createMonitoringJob(keywords, jobFrequency)
      await this.activateMonitoringJob(monitoringJob.id, subscriptionType)

      monitoringJob.initialized = true
      this.loadMonitoringJob(monitoringJob)

      this.isPageLoading = false
    },

    /*
     * Create CMS Monitoring Job
     */
    async createMonitoringJob(keywords, jobFrequency) {
      try {
        if (this.topKeywords.length === 0) {
          await this.loadToast({
            title: 'Error',
            message: 'Failed to start monitoring. At least one keyword required to start a monitoring job.',
            type: 'error',
          })
          return
        }
        const payload = {
          geoLocations: this.selectedLocations.map(location => {
            return {
              name: location.label,
              countryCode: location.country_code,
            }
          }),
          googleCampaignId: this.selectedCampaign.value,
          name: this.selectedCampaign.label,
          frequencySeconds: jobFrequency,
          keywords: keywords,
        }

        const { data: monitoringJob } = await this.$http.post(
          `/cms/site/${this.selectedSite.value}/monitoring-job/v2`,
          payload,
        )
        return monitoringJob
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to start monitoring. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Activate CMS Monitoring Job
     */
    async activateMonitoringJob(jobId, subscriptionType) {
      try {
        let evaluationUrl = subscriptionType === 'free-trial' ? '/evaluation?seconds=1209600' : ''
        await this.$http.put(`/cms/site/${this.selectedSite.value}/monitoring-job/${jobId}/start${evaluationUrl}`)

        await this.$router.push({ name: 'overview' })
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to activate monitoring job. Please try again later.',
          type: 'error',
        })
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
