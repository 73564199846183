<template>
  <div class="max-w-500 space-y-6 pb-40 p-10">
    <h3 class="h3">Start monitoring your competitors</h3>
    <p>
      Choose single or multiple keywords, specify your target location, and set the desired frequency for task
      execution.
    </p>

    <search-input
      id="select-campaign"
      :value="selectedCampaign"
      input-id="select-campaign"
      label="Select Campaign"
      sub-label="We will select the top 5 keywords from your Brand Campaign to monitor."
      placeholder="Select Campaign..."
      options-max-width="max-w-120"
      truncate-width="w-96"
      :options="campaignOptions"
      @select-item="selectCampaign"
    />
    <div
      v-if="selectedCampaign.value"
      class="space-y-2"
    >
      <div>
        <p class="text-sm font-medium">Your top keywords:</p>
        <p
          v-if="keywordsError"
          class="text-xs text-bb-red-error"
        >
          This field is required.
        </p>
      </div>
      <div v-if="!isFetchingData">
        <div v-if="topKeywords.length > 0">
          <selectable-chips
            :chips="keywordChips"
            :selected-chips="selectedKeywords"
            metric="clicks"
            @select-chip="selectChip"
          >
            <template #chip-icon>
              <ic-cursor
                size="16"
                class="text-bb-brand-purple"
              /> </template
          ></selectable-chips>
        </div>
        <div v-else>
          <p>The top five keywords are unavailable.</p>
        </div>
      </div>
      <div v-if="isFetchingData">
        <p>Loading...</p>
      </div>
    </div>

    <multi-select-search-input
      ref="select-geo-locations"
      label="Locations"
      :value="selectedLocations"
      placeholder="Select Location..."
      input-id="select-locations"
      :options="geoLocationOptions"
      :error-message="locationsError ? 'This field is required' : null"
      @update-chips="selectGeoLocation"
    >
      <template #chip-label="{ data }">
        <div class="flex items-center gap-2 py-1.5 pl-3">
          <p class="text-sm">{{ data.label }}</p>
          <p class="capitalize text-xs text-bb-text-headers bg-bb-purple-10 px-2 py-0.5 rounded-full">
            {{ data.metric }}: {{ data.metric_value }}
          </p>
        </div>
      </template>
    </multi-select-search-input>

    <div class="pt-4">
      <merge-button-round
        :disabled="!selectedCampaign.value || topKeywords.length === 0"
        @click="showSubscriptionModal"
      >
        <p>Start Monitoring</p>
      </merge-button-round>
    </div>
    <bb-base-modal
      v-if="isModalShown"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="isModalShown = false"
    >
      <template #container>
        <subscription-option
          @start-monitoring="startMonitoring"
          @close="isModalShown = false"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import MultiSelectSearchInput from '@/components/input/brightbid/MultiSelectSearchInput.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import SubscriptionOption from '@/views/site/search/competitor_monitoring/cms_onboarding/SubscriptionOption.vue'
import SelectableChips from '@/components/input/brightbid/SelectableChips.vue'
import IcCursor from '@/components/icon/brightbid/ic-cursor.vue'

export default {
  name: 'MonitoringForm',
  components: { SearchInput, BbBaseModal, SubscriptionOption, SelectableChips, IcCursor, MultiSelectSearchInput },
  props: {
    campaignOptions: {
      type: Array,
      default: () => [],
    },
    topKeywords: {
      type: Array,
      default: () => [],
    },
    topGeoLocations: {
      type: Array,
      default: () => [],
    },
    isFetchingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCampaign: {},
      modalConfig: { width: '520px', height: '320px' },
      isModalShown: false,
      selectedKeywords: [],
      selectedLocations: [],
      maxKeywordsToSelect: 5,
      keywordsError: false,
      locationsError: false,
    }
  },
  computed: {
    keywordChips() {
      return this.topKeywords.map(keyword => ({ label: keyword.keyword_text, value: keyword.clicks }))
    },
    geoLocationOptions() {
      // hard coded metric
      return this.topGeoLocations.map(location => ({
        label: location.canonical_name,
        value: location.id,
        metric: 'clicks',
        metric_value: location.metrics.clicks,
        country_code: location.country_code,
      }))
    },
  },
  watch: {
    keywordChips(keywords) {
      this.keywordsError = false
      this.selectedKeywords = keywords.slice(0, this.maxKeywordsToSelect)
    },
    isFetchingData() {
      this.keywordsError = false
      this.locationsError = false
    },
  },
  methods: {
    selectChip(chip) {
      this.keywordsError = false
      const index = this.keywordChips.findIndex(keyword => keyword.value === chip.value && keyword.label === chip.label)
      const selectedKeywordIndex = this.selectedKeywords.findIndex(
        keyword => keyword.value === chip.value && keyword.label === chip.label,
      )

      if (index === -1) return // not found

      if (selectedKeywordIndex !== -1) {
        this.selectedKeywords.splice(selectedKeywordIndex, 1)
        this.keywordsError = this.selectedKeywords.length === 0
        return
      }

      // max chip count reached
      if (this.maxKeywordsToSelect <= this.selectedKeywords.length) return

      // not yet selected
      if (selectedKeywordIndex === -1) this.selectedKeywords.push(this.keywordChips[index])
    },
    selectCampaign(campaign) {
      this.locationsError = false
      this.keywordsError = false

      this.selectedCampaign = campaign
      this.$emit('select-campaign', campaign)

      // clear selected geo-locations
      this.selectedLocations = []
      this.$refs['select-geo-locations'].clearAllChips()
      this.$emit('select-geo-location', [])
    },
    selectGeoLocation(locations) {
      this.locationsError = false
      this.selectedLocations = locations
      this.$emit('select-geo-location', locations)
    },
    showSubscriptionModal() {
      if (this.selectedKeywords.length === 0) {
        this.keywordsError = true
        return
      }
      if (this.selectedLocations.length === 0) {
        this.locationsError = true
        return
      }

      this.isModalShown = true
    },
    startMonitoring(selectedOption, jobFrequency) {
      if (!selectedOption || this.keywordsError) return

      const keywords = this.selectedKeywords.map(keyword => keyword.label)
      this.$emit('start-monitoring', keywords, selectedOption, jobFrequency)
      this.isModalShown = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
