<template>
  <label
    :for="optionId(option)"
    class="label"
    :class="{ disabled: disabled, checked: checked }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <ic-select
      :size="20"
      :checked="checked || (hover && !disabled)"
    />
    <input
      :id="optionId(option)"
      class="input"
      type="radio"
      :disabled="disabled"
      :value="optionValue(option)"
      :checked="checked"
      :name="optionId(option)"
      @change="$parent.$emit('input', $event.target.value) && $emit('input', $event.target.value)"
    />
    <slot
      v-if="!!$slots['label']"
      name="label"
    />
    <span v-else>
      {{ optionLabel(option) }}
    </span>
  </label>
</template>

<script>
import IcSelect from '@/components/icon/ic-select'

export default {
  name: 'BaseRadioButton',
  components: { IcSelect },
  props: {
    option: {
      type: [Object, String, null],
      required: true,
    },
    optionId: {
      type: Function,
      required: true,
    },
    optionValue: {
      type: Function,
      required: true,
    },
    optionLabel: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.label {
  @apply flex items-center;
  @apply cursor-pointer text-bb-mid-grey font-semibold text-xs;

  &:hover,
  &.checked {
    @apply text-bb-brand-purple;
  }

  &.disabled,
  &.disabled:hover {
    @apply text-bb-cool-grey cursor-default;
  }

  &.checked:hover {
    @apply text-bb-brand-purple cursor-default;
  }
}

.input {
  appearance: none;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

span {
  @apply ml-1;
}
</style>
