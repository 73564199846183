<template>
  <div class="p-6 space-y-4">
    <h5 class="text-base font-normal">Please select your preferred subscription option:</h5>

    <div class="relative">
      <p
        v-show="hasNoSelectedSubscription"
        class="text-sm text-bb-red-error absolute top-0"
      >
        This field is required.
      </p>
      <div class="flex gap-12 pt-6">
        <base-radio-button
          v-for="option in options"
          :key="option.value"
          :option="option"
          :option-label="() => option.value"
          :option-id="() => option.value"
          :option-value="() => option.value"
          :checked="option.value == selectedSubscription"
          @input="selectSubscription"
        >
          <template #label>
            <p class="ml-2 text-bb-text-default text-sm">{{ option.label }}</p>
          </template>
        </base-radio-button>
      </div>
    </div>
    <div>
      <search-input
        :value="jobFrequency[0]"
        input-id="run-monitoring"
        label="Run Monitoring:"
        :options="jobFrequency"
        @select-item="selectJobFrequency"
      />
    </div>

    <!--SAVE AND CANCEL BUTTONS-->
    <div class="flex justify-between items-center pt-8">
      <merge-button-round
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        button-type="primary"
        class="w-40"
        @click="startMonitoring"
      >
        <p>Continue</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import BaseRadioButton from '@/components/input/base/BaseRadioButton.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'

const jobFrequency = [
  { label: 'Daily', value: 24 },
  { label: 'Hourly', value: 1 },
]
export default {
  name: 'SubscriptionOption',
  components: { BaseRadioButton, SearchInput },
  data() {
    return {
      options: [
        {
          label: '14 Days Evaluation',
          value: 'free-trial',
        },
        {
          label: 'Full Version',
          value: 'full-version',
        },
      ],
      jobFrequency,
      selectedSubscription: null,
      hasNoSelectedSubscription: false,
      selectedJobFrequency: jobFrequency[0],
    }
  },
  methods: {
    selectSubscription(subscription) {
      this.selectedSubscription = subscription
      this.hasNoSelectedSubscription = false
    },
    selectJobFrequency(frequency) {
      this.selectedJobFrequency = frequency
    },
    startMonitoring() {
      if (!this.selectedSubscription) {
        this.hasNoSelectedSubscription = true
        return
      }
      this.$emit('start-monitoring', this.selectedSubscription, this.selectedJobFrequency.value * 3600)
    },
  },
}
</script>

<style scoped lang="scss"></style>
